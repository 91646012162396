import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.scss";

import { BrowserRouter, Switch, Route } from "react-router-dom";
import { useEffect } from "react";
import emailjs from '@emailjs/browser'
import Homepage from "./pages/Homepage";
import WorkDetails from "./pages/WorkDetails";


function App() {

  useEffect(() => {
    emailjs.init(process.env.REACT_APP_EMAILJS_PUBLIC_KEY)
  }, [])

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" component={Homepage} exact />
        <Route path="/works/:title" component={WorkDetails} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
