import { ref, getDownloadURL } from "firebase/storage";

import { storage } from "../common/config/firebase";

export const DownloadResume = async () => {
    try {
        const url = await getDownloadURL(ref(storage, "assets/resume/saadasad.pdf"));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Saad Asad.pdf");
        link.setAttribute("target", "_blank");
        link.setAttribute("rel", "noopener noreferrer");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    } catch (error) {
        throw error
    }
};
