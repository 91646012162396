import React, { useState, useEffect } from "react";
import Header from "./Header";

function Layout({ children }) {
  const [toggleHeader, setToggleHeader] = useState(false);
  const handleToggle = () => {
    setToggleHeader(!toggleHeader);
  };

  useEffect(() => {
    if (toggleHeader) {
      document.body.style.overflowY = "hidden"
    } else {
      document.body.style.overflowY = "scroll"
    }
  }, [toggleHeader]);

  return (
    <div className="site-wrapper">
      <Header toggleHeader={toggleHeader} toggleHandler={handleToggle} />
      <main
        className={
          toggleHeader ? "content float-right push overflow-hidden" : "content float-right"
        }
      >
        {children}
      </main>
    </div>
  );
}

export default Layout;
