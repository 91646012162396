import React, { useEffect, useState } from "react";
import { Link as ScrollLink } from "react-scroll";
import { Link, useRouteMatch } from "react-router-dom";
import { data } from "./../../common/constants";
import { FaLinkedin, FaGithub } from "react-icons/fa";

function Header({ toggleHeader, toggleHandler }) {
    const [currentPath, setCurrentPath] = useState("");
    const match = useRouteMatch();

    useEffect(() => {
        setCurrentPath(match.path);
    }, [match]);

    return (
        <>
            <div
                className={`header-sticky ${toggleHeader ? "hide" : ""} w-100`}
            >
                <div
                    className={
                        toggleHeader
                            ? "mobile-header py-2 px-3 mt-4 push"
                            : "mobile-header py-2 px-3 mt-4"
                    }
                >
                    <button className="menu-icon mr-2" onClick={toggleHandler}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                    <Link to="/" className="logo">
                        <img
                            src={data.avatar}
                            className="header-avatar"
                            alt={data.name}
                        />
                    </Link>
                    <Link to="/" className="site-title dot ml-2">
                        {data.name}
                    </Link>
                </div>
            </div>

            <header className={`left float-left shadow-dark ${toggleHeader ? "open" : ""}`}>
                <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    onClick={toggleHandler}
                >
                    <span aria-hidden="true">&times;</span>
                </button>
                <div className="header-inner d-flex align-items-start flex-column">
                    <Link to="/">
                        <img
                            src={data.avatar}
                            className="header-avatar"
                            alt={data.name}
                        />
                    </Link>
                    <Link to="/" className="site-title dot mt-3">
                        {data.name}
                    </Link>

                    <span className="site-slogan">{data.designation}</span>

                    <nav>
                        <ul className="vertical-menu scrollspy">
                            <li>
                                {currentPath === "/" ? (
                                    <ScrollLink
                                        activeClass="active"
                                        to="section-home"
                                        spy={true}
                                        smooth={true}
                                        offset={-75}
                                        duration={500}
                                        onClick={toggleHandler}
                                    >
                                        <i className="icon-home"></i>Home
                                    </ScrollLink>
                                ) : (
                                    <Link to="/">
                                        <i className="icon-home"></i>Home
                                    </Link>
                                )}
                            </li>
                            <li>
                                {currentPath === "/" ? (
                                    <ScrollLink
                                        activeClass="active"
                                        to="section-about"
                                        spy={true}
                                        smooth={true}
                                        offset={-75}
                                        duration={500}
                                        onClick={toggleHandler}
                                    >
                                        <i className="icon-user"></i>About
                                    </ScrollLink>
                                ) : (
                                    <Link to="/">
                                        <i className="icon-user"></i>About
                                    </Link>
                                )}
                            </li>
                            <li>
                                {currentPath === "/" ? (
                                    <ScrollLink
                                        activeClass="active"
                                        to="section-experiences"
                                        spy={true}
                                        smooth={true}
                                        offset={-75}
                                        duration={500}
                                        onClick={toggleHandler}
                                    >
                                        <i className="icon-graduation"></i>
                                        Resume
                                    </ScrollLink>
                                ) : (
                                    <Link to="/">
                                        <i className="icon-graduation"></i>
                                        Resume
                                    </Link>
                                )}
                            </li>
                            <li>
                                {currentPath === "/" ? (
                                    <ScrollLink
                                        activeClass="active"
                                        to="section-portfolios"
                                        spy={true}
                                        smooth={true}
                                        offset={-75}
                                        duration={500}
                                        onClick={toggleHandler}
                                    >
                                        <i className="icon-grid"></i>Works
                                    </ScrollLink>
                                ) : (
                                    <Link to="/">
                                        <i className="icon-grid"></i>Works
                                    </Link>
                                )}
                            </li>
                            <li>
                                {currentPath === "/" ? (
                                    <ScrollLink
                                        activeClass="active"
                                        to="section-contact"
                                        spy={true}
                                        smooth={true}
                                        offset={-75}
                                        duration={500}
                                        onClick={toggleHandler}
                                    >
                                        <i className="icon-phone"></i>Contact
                                    </ScrollLink>
                                ) : (
                                    <Link to="/">
                                        <i className="icon-phone"></i>Contact
                                    </Link>
                                )}
                            </li>
                        </ul>
                    </nav>
                    <div className="footer mt-auto">
                        <ul className="social-icons list-inline">
                            {data?.social?.linkedin && (
                                <li className="list-inline-item">
                                    <a
                                        href={data.social.linkedin}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        <FaLinkedin />
                                    </a>
                                </li>
                            )}
                            {data?.social?.github && (
                                <li className="list-inline-item">
                                    <a
                                        href={data.social.github}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        <FaGithub />
                                    </a>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </header>
        </>
    );
}

export default Header;
