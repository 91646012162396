import React from "react";

function Experience({
    experience: { year, organization, designation, techStack, location, content },
}) {
    const ListItem = (props) => {
        const { title, skills } = props;
        return (
            <div className="p-3 list-bg">
                <h4 className="mt-0 text-left techstack">{title}</h4>
                <div className="d-flex flex-wrap">
                    {skills?.map((tech) => (
                        <div
                            key={tech}
                            className="mr-2 mb-2 badge-pill badge-dark badge-red"
                        >
                            {tech}
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    return (
        <div className="entry">
            <div className="title">
                <span className="experience-year">{year}</span>
            </div>
            <div className="body">
                <div className="experience-title w-100">
                    <h4 className="m-0 text-left organization">
                        {organization}
                    </h4>
                    <p className="org-location">{location}</p>
                </div>
                <p className="mt-0 text-left designation">{designation}</p>
                <hr className="hr bg-dark" />
                <p className="my-2">{content}</p>

                <h4 className="mt-4 mb-1 text-left tech-title">Tech Stack</h4>
                
                <div>
                    {techStack?.frontend?.length > 0 && (
                        <ListItem
                            title="Frontend"
                            skills={techStack?.frontend}
                        />
                    )}

                    {techStack?.backend?.length > 0 && (
                        <ListItem title="Backend" skills={techStack?.backend} />
                    )}
                </div>
            </div>
        </div>
    );
}

export default Experience;
