import React from "react";
import Layout from "../components/Layout/Layout";
import { data } from './../common/constants'

function WorkDetails(props) {
  const title = props?.match?.params?.title
  const content = data?.projects?.[title]

  return (
    <Layout>
      <section className="shadow-blue dark-bg shadow padding mt-0">
        <ul className="list-inline portfolio-info mt-0">
          <li className="list-inline-item">
            <i className="icon-user"></i>
            {content.author}
          </li>
          {content?.link && <li className="list-inline-item">
            <a
              href={content?.link}
              target="__blank"
              rel="noopener noreferrer"
            >
              <i className="icon-link"></i>
              {content?.link}
            </a>
          </li>}
        </ul>
        <div>
          <h3 id={content.name}>{content.name}</h3>
          <p><img alt="alt" src="/images/portfolio/1.jpg" width='100%' /></p>
          <h4 className="mt-5" id="overview">Overview</h4>
          {content.description.map(desc => <p className="text-justify mb-5">{desc}</p>)}
          <h4 id="tech-stack">Tech Stack</h4>
          <div className="d-flex flex-wrap">
            {content.techStack.map(tech => <div className="mr-2 mb-2 badge-pill badge-dark badge-red">{tech}</div>)}
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default WorkDetails;