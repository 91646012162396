import React from "react";
import Education from "../Items/Education";
import { data } from '../../common/constants'

function Educations() {
  return (
    <div className="timeline">
      {data.education.map((experience) => (
        <Education experience={experience} key={experience.id} />
      ))}
      <span className="timeline-line"></span>
    </div>
  );
}

export default Educations;
