import React, { useState } from "react";
import Portfolio from "../Items/Portfolio";
import { data } from "./../../common/constants";
import { WorkDrawer } from "../Items/WorkDrawer";

function Portfolios() {
    const [content, setContent] = useState();
    const [openDrawer, setOpenDrawer] = useState(false);

    return (
        <>
            <div className="row portfolio-wrapper">
                {data.projects.projectCards.map((item) => (
                    <div
                        className="col-md-4 col-sm-6 grid-item"
                        onClick={() => {
                            setContent(data?.projects?.[item?.slug]);
                            setOpenDrawer(true);
                        }}
                        key={item.id}
                    >
                        <Portfolio portfolio={item} />
                    </div>
                ))}
            </div>
            <WorkDrawer
                open={openDrawer}
                handleClose={() => {
                    setOpenDrawer(false);
                    setContent();
                }}
                content={content}
            />
        </>
    );
}

export default Portfolios;
