import * as React from "react";
import {
    Drawer,
    Toolbar,
    Button,
    DialogContent,
    DialogActions,
    IconButton,
    AppBar,
    Typography,
} from "@mui/material";
import { Link as ScrollLink } from "react-scroll";
import { Close } from "@mui/icons-material";

const WorkDrawer = (props) => {
    const { open, handleClose, content } = props;
    const drawerBody = () => {
        return (
            <main className="content m-0">
                <ul className="list-inline portfolio-info mt-0">
                    <li className="list-inline-item">
                        <i className="icon-user"></i>
                        {content?.author}
                    </li>
                    {content?.link && (
                        <li className="list-inline-item">
                            <a
                                href={content?.link}
                                target="__blank"
                                rel="noopener noreferrer"
                            >
                                <i className="icon-link"></i>
                                {content?.link}
                            </a>
                        </li>
                    )}
                </ul>
                <div>
                    <h3 id={content?.name}>{content?.name}</h3>
                    <p>
                        <img
                            alt="alt"
                            src={content?.image}
                            width="100%"
                        />
                    </p>
                    <h4 className="mt-5" id="overview">
                        Overview
                    </h4>
                    {content?.description?.map((desc, index) => (
                        <p key={index} className="text-justify text-light mb-4">{desc}</p>
                    ))}

                    <h4 id="tech-stack">Tech Stack</h4>
                    <div className="d-flex flex-wrap">
                        {content?.techStack?.map((tech, index) => (
                            <div key={index} className="mr-2 mb-2 badge-pill badge-dark badge-red">
                                {tech}
                            </div>
                        ))}
                    </div>
                </div>
            </main>
        );
    };

    return (
        <React.Fragment key={"right"}>
            <Drawer
                anchor={"right"}
                open={open}
                onClose={handleClose}
                style={{ position: "relative", backgroundColor: "red" }}
            >
                <AppBar className="draw-appbar">
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <Close />
                        </IconButton>
                        <Typography className="ml-2"></Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent className="drawer-body ">
                    {drawerBody()}
                </DialogContent>
                <DialogActions className="p-0 draw-appbar">
                    <Toolbar className="d-flex justify-content-between w-100">
                        <Button
                            variant="outlined"
                            className="text-light border-dark"
                            onClick={handleClose}
                        >
                            Close
                        </Button>
                        <ScrollLink
                            activeClass="active"
                            to="section-contact"
                            spy={true}
                            smooth={true}
                            offset={-75}
                            duration={500}
                        >
                            <Button
                                variant="contained"
                                className="text-light drawer-hire-btn"
                                onClick={handleClose}
                            >
                                Hire Me
                            </Button>
                        </ScrollLink>
                    </Toolbar>
                </DialogActions>
            </Drawer>
        </React.Fragment>
    );
};

export { WorkDrawer };
