import React, { useState } from "react";
import { Snackbar, Alert } from "@mui/material";
import { Link as ScrollLink } from "react-scroll";

import { data } from "../../common/constants";
import { DownloadResume } from "../../Services/FirebaseAPIs";

function About() {
    const [downloading, setDownloading] = useState(false);
    const [error, setError] = useState(false);

    const downloadResume = async () => {
        try {
            setDownloading(true);
            await DownloadResume();
        } catch (error) {
            setError(true);
        } finally {
            setDownloading(false);
        }
    };

    return (
        <div className="row">
            <div className="col-md-3">
                <img src={data.avatar} alt={data.name} />
            </div>
            <div className="col-md-9">
                <h2 className="mt-4 mt-md-0 mb-4 text-red">Greetings!</h2>
                {data.aboutMe.map((about, index) => (
                    <p key={index} className="mb-4 text-justify">
                        {about}
                    </p>
                ))}
                <div className="row my-4">
                    <div className="col-md-6">
                        <p className="mb-2">
                            Name: <span className="text-red">{data.name}</span>
                        </p>
                        <p className="mb-0">
                            Birthday:{" "}
                            <span className="text-red">{data.birthday}</span>
                        </p>
                    </div>
                    <div className="col-md-6 mt-2 mt-md-0 mt-sm-2">
                        <p className="mb-2">
                            Location:{" "}
                            <span className="text-red">{data.location}</span>
                        </p>
                        <p className="mb-0">
                            Email:{" "}
                            <span className="text-red">{data.email}</span>
                        </p>
                    </div>
                </div>
                <button
                    disabled={downloading}
                    onClick={downloadResume}
                    className="btn btn-default mr-3"
                >
                    <i className="icon-cloud-download"></i>Download CV
                </button>
                <ScrollLink
                    activeClass="active"
                    to="section-contact"
                    spy={true}
                    smooth={true}
                    duration={500}
                    offset={50}
                    className="btn btn-border-light mt-2 mt-md-0 mt-xs-2"
                >
                    <i className="icon-envelope"></i>Hire me
                </ScrollLink>
            </div>
            <Snackbar
                open={error}
                autoHideDuration={3000}
                onClose={() => setError(false)}
            >
                <Alert onClose={() => setError(false)} severity="error">
                    Something went wrong, please try again!
                </Alert>
            </Snackbar>
        </div>
    );
}

export default About;
