import avatar from "./images/avatar.png";
import watchsports from "./images/Watch-Sports.jpg";
import glattmart from "./images/Glatt-Mart.jpg";
import llc from "./images/LED-Close-Outs.jpg";
import essence from "./images/Essence.jpg";
import coin from "./images/Coin-Telegraph.jpg";
import afro from "./images/Afro-Punk.jpg";
import hero from "./images/main.jpg";
import glamour from './images/glamour.jpg'

export const data = {
    name: "Saad Asad",
    location: "Dallas, TX",
    birthday: "02 Oct, 1997",
    email: "hello@saadasad.dev",
    phone: "(469) 942-9090",
    avatar: avatar,
    heroImage: hero,
    social: {
        linkedin: "https://linkedin.com/in/saad-asad-564b711a3",
        github: "https://github.com/saad-asad",
    },
    herosectionAbout: [
        `Hey there, I'm Saad Asad - a software engineer who's all about that base (systemized web development, that is!). With over 5 years of experience under my belt, I've got the skills and expertise to deliver high-quality results within the tightest of deadlines.`,
        `But what really sets me apart is my love for next-gen technologies. From ReactJs to Nodejs, I'm like a tech-savvy wizard who can whip up innovative solutions faster than you can say 'cutting-edge'. And when it comes to creating results that speak for themselves, well, let's just say I'm like a genie in a bottle (except without the whole three-wishes thing).`,
    ],
    aboutMe: [
        `This is Saad again - a software engineer who's passionate about developing web applications that are as efficient as a Formula One race car! With over 5 years of experience, I have a proven track record of delivering top-notch solutions that meet project requirements within tight timelines.`,
        `My technical expertise spans a wide range of State-of-the-art technologies, which means I'm like a Swiss Army Knife of web development. From ReactJs to Nodejs, from GCP to AWS, and from HTML to ElasticSearch - I've got you covered! I'm committed to excellence, and I'm dedicated to staying up-to-date with the latest trends in web development.`,
        `When I'm not busy tinkering with code, you can usually find me exploring new technologies or challenging my friends to a game of foosball. I'm a true believer in the power of teamwork, and I'm always looking for new ways to collaborate and create something awesome.`,
        `So, whether you're looking to build complex web applications or develop elegant solutions for challenging problems, I'm your guy! With my proven track record of success and my unwavering passion for my work, I'm a force to be reckoned with in the world of software engineering. Let's create something awesome together!`,
    ],
    skillContent:
        "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarks grove right at the coast of the Semantics",
    progressData: [
        {
            id: 1,
            name: "ReactJs",
            percentage: 90,
        },
        {
            id: 2,
            name: "NodeJs",
            percentage: 90,
        },
        {
            id: 3,
            name: "ExpressJs",
            percentage: 85,
        },
        {
            id: 4,
            name: "NextJs",
            percentage: 85,
        },
        {
            id: 5,
            name: "GCP",
            percentage: 85,
        },
        {
            id: 6,
            name: "AWS",
            percentage: 80,
        },
        {
            id: 7,
            name: "MySQL/NoSql",
            percentage: 85,
        },
        {
            id: 8,
            name: "Neo4j",
            percentage: 85,
        },
        {
            id: 9,
            name: "JavaScript",
            percentage: 90,
        },
        {
            id: 10,
            name: "HTML5/CSS3",
            percentage: 90,
        },
        {
            id: 11,
            name: "MaterialUI/AntD",
            percentage: 75,
        },
        {
            id: 12,
            name: "WordPress",
            percentage: 85,
        },
        {
            id: 13,
            name: "Laravel",
            percentage: 85,
        },
        {
            id: 14,
            name: "PHP",
            percentage: 85,
        },
        {
            id: 15,
            name: "Python",
            percentage: 75,
        },
        {
            id: 16,
            name: "Git",
            percentage: 65,
        },
    ],
    education: [
        {
            id: 1,
            year: "2016 - 2020",
            degree: "B.S. Software Engineering - The University Of Lahore",
            content: `Specialized in engineering web applications and AI systems, analyzing user requirements, designing, developing and testing software applications.`,
        },
    ],
    projects: {
        projectCards: [
            {
                id: 2,
                name: "WatchSports",
                category: ["Web Application"],
                image: watchsports,
                slug: "watchsports",
            },
            {
                id: 3,
                name: "Glatt Mart",
                category: ["Web Application"],
                image: glattmart,
                slug: "glattmart",
            },
            {
                id: 4,
                name: "Glamour Beauty Center",
                category: ["Web Application"],
                image: glamour,
                slug: "glamourbeautycenter",
            },
            {
                id: 5,
                name: "Led Lighting Closeouts",
                category: ["Web Application"],
                image: llc,
                slug: "llc",
            },
            {
                id: 6,
                name: "Essence",
                category: ["Web Application"],
                image: essence,
                slug: "essence",
            },
            {
                id: 7,
                name: "Cointelegraph",
                category: ["Web Application"],
                image: coin,
                slug: "cointelegraph",
            },
            {
                id: 8,
                name: "Afropunk",
                category: ["Web Application"],
                image: afro,
                slug: "afropunk",
            },
        ],
        watchsports: {
            name: "WatchSports",
            designation: "Backend Developer",
            description: [
                `Watch Sports is a mobile and web application that allows users to stream various sports events, including college, professional, high school, Olympic, and esports. The app also serves as a live event streaming guide with a Connect-to-Watch feature. I utilized AWS, Nodejs, and ExpressJs to develop a notification system for the application.`,
            ],
            techStack: [
                "NodeJs",
                "ExpressJs",
                "AWS",
                "Neo4j",
                "SQS",
                "Serverless",
            ],
            author: "Saad Asad",
            link: "https://apps.apple.com/us/app/watchsports/id1543955157",
            image: watchsports,
        },
        glattmart: {
            name: "Glatt Mart",
            designation: "FullStack Developer",
            description: [
                `Glatt Mart is a renowned store located in Los Angeles, California, that specializes in kosher products. The store offers a vast selection of items carefully chosen to meet the unique requirements of its diverse clientele. Glatt Mart also provides catering and event planning services, and its physical location offers in-store shopping. Our team utilized the powerful combination of React, AWS, and Nodejs to create a consistent and dynamic user experience for the site visitors. By leveraging these technologies, I was able to build a responsive and feature-rich website that seamlessly integrates with the client's existing systems.`,
            ],
            techStack: [
                "NextJs",
                "NodeJs",
                "ExpressJs",
                "AWS",
                "Antd",
                "Bootstrap",
            ],
            author: "Saad Asad",
            link: "https://laglattmart.com",
            image: glattmart,
        },
        glamourbeautycenter: {
            name: "Glamour Beauty Center",
            designation: "FullStack Developer",
            description: [
                `Glamour Beauty Center is a popular online destination for beauty enthusiasts and professionals looking for high-quality beauty products. As an e-commerce store, it offers a wide range of beauty products. The website is designed to provide a user-friendly shopping experience, with easy navigation and a search function that allows customers to quickly find the products they need.`,
                `I was responsible for creating a robust and user-friendly online platform for customers to browse, search, and purchase a wide range of beauty products. I further implemented back-end functionality to enable secure payment processing, inventory management, and order fulfilment.`,
            ],
            techStack: ["ReactJs", "NodeJs", "ExpressJs", "GCP", "MySql"],
            author: "Saad Asad",
            link: "https://glamourbeautycenter.com",
            image: glamour,
        },
        llc: {
            name: "Led Lighting Closeouts",
            designation: "FullStack Developer",
            description: [
                `Led Lighting Closeouts LLC is a new and upcoming e-commerce store that specializes in providing high-quality LED lighting products and other illumination solutions to customers worldwide. Their inventory includes a wide range of LED lighting products. They take pride in offering energy-efficient and eco-friendly LED lighting solutions that are both affordable and long-lasting. The company's goal is to provide customers with a hassle-free shopping experience, with easy online ordering and fast shipping options.`,
                `The web application for Led Lighting Closeouts LLC was developed using the popular front-end framework ReactJS, along with NodeJS and Google Cloud Platform (GCP) for the back-end. The site was designed with user experience in mind, providing customers with an easy-to-use and intuitive interface to search for and purchase their desired products. The use of GCP ensures that the site is scalable, reliable, and secure, ensuring that customer data is protected at all times.`,
            ],
            techStack: [
                "ReactJs",
                "NodeJs",
                "ExpressJs",
                "GCP",
                "Material Ui",
                "MySql",
            ],
            author: "Saad Asad",
            link: "",
            image: llc,
        },
        essence: {
            name: "Essence",
            designation: "FullStack Developer",
            description: [
                `Essence Communications is a media company that primarily focuses on Black women. They provide content across various media platforms, including print, digital, social media, and live events. They cover topics such as beauty, fashion, lifestyle, entertainment, and news that are relevant to Black women. In addition to media, they also have a commerce division that offers various products and services catering to the same audience.`,
                `Their commitment to empowering Black women and providing them with a platform to share their stories and perspectives has made them a leading voice in the media industry. I was involved in building the user interface for the workflows modules and establishing the backend and CMS. This was accomplished by utilizing vanilla JS, PHP, and WordPress.`,
            ],
            techStack: ["Vanilla Js", "PHP", "WordPress"],
            author: "Saad Asad",
            link: "https://www.essence.com",
            image: essence,
        },
        cointelegraph: {
            name: "Cointelegraph",
            designation: "FullStack Developer",
            description: [
                `Cointelegraph is a prominent media outlet that specializes in providing up-to-date news, analysis, and insights on the ever-changing cryptocurrency industry. The platform offers comprehensive coverage of a wide range of topics related to blockchain technology, decentralized finance, cryptocurrencies, and emerging technologies. Its coverage includes breaking news, market trends, regulatory updates, and new developments in the crypto industry. Cointelegraph aims to provide readers with in-depth reporting and analysis to help them make informed decisions on investing, trading, and engaging with cryptocurrencies and blockchain technology.`,
                `I played a crucial role in developing the user interface and workflow of their platform and aimed to deliver a modern, intuitive, and user-friendly interface that would make the user experience seamless and engaging. I also took the challenge to develop the backend and CMS of the platform using PHP and WordPress and ensured that the CMS was easy to use and could be customized according to the organization's needs.`,
            ],
            techStack: ["Vanilla Js", "PHP", "WordPress"],
            author: "Saad Asad",
            link: "https://cointelegraph.com",
            image: coin,
        },
        afropunk: {
            name: "Afropunk",
            designation: "FullStack Developer",
            description: [
                `Afropunk is a media company and online platform that celebrates black culture, music, and art. It was founded in 2002 as a way to give visibility to alternative black culture that is often marginalized in mainstream media. The platform features articles, videos, podcasts, and event coverage that highlight the voices and stories of black creatives and activists.`,
                `I was involved in the complete development of UI and workflow for the project and took the responsibility for the development of the backend and CMS using PHP and WordPress, ensuring a smooth and user-friendly experience for the website's users. By leveraging the power of these technologies, I was able to create a website that was both aesthetically pleasing and highly functional, meeting the client's specific requirements and expectations.`,
            ],
            techStack: ["Vanilla Js", "PHP", "WordPress"],
            author: "Saad Asad",
            link: "https://afropunk.com",
            image: afro,
        },
    },
    experience: [
        {
            id: 1,
            year: "Dec,2022 - Present",
            organization: "SeedTech",
            location: "Los Angeles, CA",
            designation: "Team Lead",
            content: `SeedTech is full-stack web development & Digital Marketing agency with
            IT support entities. I joined as a team lead, I'm currently responsible for
            developing software systems and mentoring newcomers in reactJS,
            nodeJS, and other related technologies.`,
            techStack: {
                frontend: [
                    "ReactJS",
                    "NextJs",
                    "JavaScript",
                    "HTML5/CSS3",
                    "MaterialUI",
                    "Antd",
                    "Bootstrap",
                ],
                backend: [
                    "Python",
                    "NodeJS",
                    "ExpressJs",
                    "GCP",
                    "AWS",
                    "MySQL",
                    "NoSQL",
                    "Neo4j",
                ],
            },
        },
        {
            id: 2,
            year: "Dec,2021 - Dec,2022",
            organization: "Excersys",
            location: "Los Angeles, CA",
            designation: "Team Lead",
            content: `Excersys provides Software development and related services to small
            to mid-size businesses. I worked there as a Lead Developer on several
            projects. I had a wide array of responsibilities spanning from application
            development, and deployment to mentoring and collaborating with the
            team and the clients.`,
            techStack: {
                frontend: [
                    "ReactJS",
                    "JavaScript",
                    "HTML5/CSS3",
                    "NextJs",
                    "MaterialUI",
                    "Antd",
                    "Bootstrap",
                ],
                backend: [
                    "Python",
                    "NodeJS",
                    "ExpressJs",
                    "GCP",
                    "AWS",
                    "MySQL",
                    "Firestore",
                    "Neo4j",
                ],
            },
        },
        {
            id: 3,
            year: "Feb,2021 - Dec,2021",
            organization: "iMationSoft",
            location: "Los Angeles, CA",
            designation: "Team Lead",
            content: `iMationSoft provides web development services to businesses across the
            United States. I joined as a Jr. Full-stack developer and worked my way to
            Sr. Full-stack developer in a year. I was responsible for developing and
            deploying solutions to a wide array of software-related problems`,
            techStack: {
                frontend: [
                    "ReactJS",
                    "JavaScript",
                    "HTML5/CSS3",
                    "MaterialUI",
                    "Antd",
                    "Bootstrap",
                ],
                backend: [
                    "Python",
                    "NodeJS",
                    "ExpressJs",
                    "GCP",
                    "AWS",
                    "MySQL",
                    "Firestore",
                ],
            },
        },
        {
            id: 4,
            year: "2020 - 2021",
            designation: "FullStack Developer",
            techStack: {
                frontend: [
                    "ReactJS",
                    "JavaScript",
                    "HTML5/CSS3",
                    "MaterialUI",
                    "Antd",
                    "Bootstrap",
                ],
                backend: [
                    "Python",
                    "NodeJS",
                    "ExpressJs",
                    "GCP",
                    "AWS",
                    "MySQL",
                    "Firestore",
                ],
            },
        },
    ],
    testimonials: [
        {
            id: 1,
            content:
                "I recently worked with Saad on a complex React project, and I was blown away by his expertise and attention to detail. He really knows his way around the framework, and was able to implement some advanced features that I didn't think were possible. His communication was excellent, and he was always able to explain technical concepts in a way that I could understand. I highly recommend Saad for any React project!",
            authorName: "Adriana",
            authorStatus: "Manager, E-Signature Company",
        },
        {
            id: 2,
            content:
                "Saad is a true React ninja! He was able to take my mockups and turn them into a fully-functional React app in no time. His attention to detail was impressive, and he was always willing to go the extra mile to ensure the app looked and behaved exactly as I envisioned, He's a pleasure to work with.",
            authorName: "Omid",
            authorStatus: "CEO, Tech Company",
        },
        {
            id: 3,
            content:
                "Saad was a pleasure to work with on our React-based e-commerce site. He was able to customize the platform to our specific needs, and was always available to answer our questions or make changes on the fly. His code was clean and well-documented, which made it easy for our internal team to pick up where he left off. We're thrilled with the final product, and would definitely hire Saad again for future projects",
            authorName: "",
            authorStatus: "Owner, Printing Company",
        },
        {
            id: 4,
            content:
                "I hired Saad to optimize my existing React app, and I couldn't be happier with the results. He was able to identify some performance bottlenecks and make some clever optimizations that significantly improved the user experience. He was also able to refactor some legacy code to make it more maintainable and extensible in the future. I highly recommend Saad for any React consulting or development work",
            authorName: "",
            authorStatus: "Owner, Electronics Ecommerce Store",
        },
    ],
};
