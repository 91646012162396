import React from "react";

function Portfolio(props) {
    const { portfolio } = props;
    const { id, name, category, image } = portfolio;

    return (
        <div key={id} className="portfolio-item">
            <div className="details">
                <h4 className="title">{name}</h4>
                <span className="term">{category.join(",  ")}</span>
            </div>
            {/* <span className="plus-icon"><i className="icon-arrow-right-circle"></i></span> */}
            <div className="thumb">
                <img src={image} alt={name} />
                <div className="mask"></div>
            </div>
        </div>
    );
}

export default Portfolio;
